import React, { useState } from "react";
// Styles
import styles from "../styles/presentation.module.scss";
// Images
import Logo from "../images/logoheader.svg";
// Components
import Modal from "../Components/Modal";
import ToS from "../Components/ToS";

import axiosBackendForm from "./APIForm.js";
import Swal from "sweetalert2";
import 'element-closest';

function Presentation() {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    assunto: "",
    mensagem: "",
    concorda: false,
    method: "submit-message",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitingMessage, setIsSubmitingMessage] = useState(false);

  // Preenche o form
  const formChangeHandler = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Submete o form
  const formSubmitHandler = (e) => {
    e.preventDefault();

    const btn = e.target;
    if (!btn || isSubmitingMessage) {
      return;
    }

    setIsSubmitingMessage(true);

    const bodyFormData = new FormData();
    bodyFormData.append("name", formData.nome);
    bodyFormData.append("email", formData.email);
    bodyFormData.append("phone", formData.telefone);
    bodyFormData.append("subject", formData.assunto);
    bodyFormData.append("message", formData.mensagem);
    bodyFormData.append("terms", formData.concorda);
    bodyFormData.append("method", formData.method);
    axiosBackendForm
      .post("/endpoint", bodyFormData)
      .then((res) => {
        const { success, message } = res.data;
        const is_success = success === 1;

        if (is_success) {
          setFormData((prevState) => {
            return {
              ...prevState,
              nome: "",
              email: "",
              telefone: "",
              assunto: "",
              mensagem: "",
              concorda: false,
            };
          });
          btn.closest("form").reset();
        }

        showAlert(message, is_success);
      })
      .catch((e) => {})
      .then(() => {
        setIsSubmitingMessage(false);
      });
  };

  const showAlert = (message, is_success = true) => {
    let swal_options = { icon: "info", title: "Nove Corporate Finance" };

    if (!is_success) {
      swal_options = {
        ...swal_options,
        icon: "error",
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "OK",
      };
    }

    Swal.fire({ ...swal_options, html: message });
  };

  return (
    <>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <ToS
            acceptHandler={() => {
              setFormData({ ...formData, concorda: true });
              setIsModalOpen(false);
            }}
          />
        </Modal>
      )}
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <img src={Logo} alt="logo" />
          </div>
          <div className={styles.content}>
            <div className={styles.contentWrap}>
              <div className={styles.text}>
                <h1>SITE EM DESENVOLVIMENTO</h1>
                <p className={styles.firstp}>
                  A F9 agora é NOVE. Por essa razão estamos a proceder à
                  remodelação de toda a nossa imagem assim como deste website.
                </p>
                <p className={styles.secondp}>
                  Se desejar contactar-nos, por favor preencha o formulário.
                </p>
                <p>
                  Entraremos em contacto consigo o mais brevemente possível.
                </p>
              </div>
            </div>
            <div className={styles.form}>
              <form onSubmit={formSubmitHandler}>
                <input
                  required
                  type="text"
                  name="nome"
                  placeholder="NOME:"
                  onChange={formChangeHandler}
                />
                <div className={styles.inline}>
                  <input
                    required
                    type="email"
                    name="email"
                    placeholder="E-MAIL:"
                    onChange={formChangeHandler}
                  />
                  <input
                    required
                    type="text"
                    name="telefone"
                    placeholder="TELEFONE:"
                    onChange={formChangeHandler}
                  />
                </div>
                <input
                  required
                  type="text"
                  name="assunto"
                  placeholder="ASSUNTO:"
                  onChange={formChangeHandler}
                />
                <div className={styles.msgtext}>
                  <h5>MENSAGEM</h5>
                  <p>
                    Se desejar desde já esclarecer alguma dúvida ou referir o
                    motivo do seu contacto pode fazê-lo aqui.
                  </p>
                </div>
                <textarea
                  required
                  name="mensagem"
                  onChange={formChangeHandler}
                />
                <div className={styles.check}>
                  <label>
                    <input
                      required
                      type="checkbox"
                      checked={formData.concorda}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          concorda: !formData.concorda,
                        })
                      }
                    />
                    <span>Li e aceito:</span>
                  </label>
                  <span
                    className={styles.btntos}
                    onClick={() => setIsModalOpen(true)}
                  >
                    Política de Privacidade e Segurança
                  </span>
                </div>
                <div className={styles.btncontainer}>
                  <button
                    type="submit"
                    className={isSubmitingMessage ? styles.mProgress : ""}
                  >
                    ENVIAR
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Presentation;
