import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
// Styles
import styles from "../styles/modal.module.scss";
import MdClose from "../images/close.svg";
import useOnClickOutside from "../hooks/useOnClickOutside";

const Modal = (props) => {
  const ModalRoot = document.getElementById("modal");
  const ref = useRef();
  useOnClickOutside(ref, props.onClose);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={styles.modalWrapperStyle}>
      <div className={styles.modalBackdropStyle}>
        <div className={styles.modalStyle} ref={ref}>
          <div className={styles.closeDivStyle}>
            <img
              src={MdClose}
              alt="close icon"
              style={{ cursor: "pointer" }}
              fontSize="18px"
              onClick={props.onClose}
            />
          </div>
          <div className={styles.contentDivStyle}>{props.children}</div>
        </div>
      </div>
    </div>,
    ModalRoot
  );
};

export default Modal;
