import "./App.module.css"
// Components
import Presentation from "./Components/Presentation"
import Footer from "./Components/Footer"

function App() {
  return (
    <>
      <Presentation />
      <Footer />
    </>
  )
}

export default App
