import React from "react"
// Styles
import styles from "../styles/footer.module.scss"
// Images
import LogoFooter from "../images/logofooter.svg";
import Dot from "../images/dot.svg";
// import Instagram from "../images/instagram.svg";
// import Facebook from "../images/facebook.svg";
import Linkedin from "../images/linkedin.svg";

function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <img src={LogoFooter} alt="logo" />
          </div>
          <div className={styles.body}>
            <div className={styles.textWrapper}>
              <div className={styles.text}>
                <h2>M&A</h2>
                <ul>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Buyside and sellside transactions</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Leverage transactions (MBO, LBO)</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Valuation</span>
                  </li>
                </ul>
              </div>
              <div className={styles.text}>
                <h2>
                  Equity and <br /> Fund raising
                </h2>
                <ul>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Start-up fund raising</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Capital Structure advice</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Refinancing</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Acquisition finance</span>
                  </li>
                </ul>
              </div>
              <div className={styles.text}>
                <h2>
                  Financial and <br /> evaluation advisory
                </h2>
                <ul>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Business plans</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Economic and financial viability analysis</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Valuation</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Arbitrage</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Restructuring</span>
                  </li>
                </ul>
              </div>
              <div className={styles.text}>
                <h2>
                  Project and <br /> Structured Finance
                </h2>
                <ul>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>PPP</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Public tenders</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Rebalancing</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Refinancing</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Restructuring</span>
                  </li>
                  <li>
                    <img src={Dot} alt="dot" style={{ marginRight: "10px" }} />
                    <span>Project monitoring</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.contacts}>
              <div className={styles.contactwrapper}>
                <div className={styles.socialmedia}>
{/*
                  <a href="https://google.com">
                    <img src={Facebook} alt="Facebook icon" />
                  </a>
*/}
                  <a href="https://www.linkedin.com/company/f9-consulting/" target="_blank" rel="noreferrer">
                    <img src={Linkedin} alt="Linkedin icon" />
                  </a>
{/*
                  <a href="https://google.com">
                    <img src={Instagram} alt="Instagram icon" />
                  </a>
*/}
                  <div />
                </div>
                <div className={styles.countrywrap}>
                  <div className={styles.contactpt}>
                    <h3>Portugal</h3>
                    <p>Rua Prof. Carlos Alberto da Mota Pinto, 9,</p>
                    <p>Edifício Amoreiras Plaza, 7º A 1070-374 Lisboa</p>
                    <p>(+351) 213 826 720 I (+351) 213 862 032</p>
                    <p>geral@f9consulting.com</p>
                  </div>
                  <div className={styles.contactbr}>
                    <h3>Brasil</h3>
                    <p>Rua Fidêncio Ramos, 160, conjunto 801</p>
                    <p>CEP 04551-010, Vila Olímpia São Paulo – Brasil</p>
                    <p>Tel.: +55 11 6848-8140</p>
                    <p>cristina.zanatti@novecorporatefinance.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
