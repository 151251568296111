import React, { useRef, useState } from "react";
// Styles
import styles from "../styles/tos.module.scss";

function ToS({ acceptHandler }) {
  const [isBottom, setIsBottom] = useState(false);
  const elem = useRef();

  const checkBottom = () => {
    if (
      elem.current.scrollHeight - elem.current.scrollTop ===
      elem.current.clientHeight
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  return (
    <div className={styles.container} ref={elem} onScroll={checkBottom}>
      <div className={styles.title}>Termos e Condições</div>
      <p>
        O conteúdo deste sítio é disponibilizado com o objectivo de fornecer
        informações de carácter geral e não constitui a prestação oficial de
        qualquer serviço. A Nove Corporate Consulting exclui a sua
        responsabilidade por quaisquer danos que poderão ocorrer relacionados
        com a informação contida neste sítio. Este sítio é meramente
        informativo.
        <br />
        <br />A Nove Corporate Consulting autoriza o descarregamento e
        armazenamento temporário do conteúdo deste sítio para efeitos de
        visionamento do mesmo num computador pessoal. A reprodução,
        armazenamento permanente ou retransmissão do conteúdo deste sítio são
        expressamente proibidos sem a autorização prévia e por escrito da FNove
        Corporate Consulting. Todos os conteúdos deste sítio (nomeadamente
        textos, imagens, documentos em pdf, links,...) e respectivos direitos
        são da responsabilidade e titularidade exclusiva da Nove Corporate
        Consulting.
      </p>
      <div className={styles.title}>A nossa política de cookies</div>
      <p>
        A nossa Política de Cookies (e de Privacidade) explica como iremos
        tratar qualquer tipo de informação que nos fornecer quando navegar neste
        website, conforme aplicável.
      </p>
      <div className={styles.title}>Os Cookies e a sua finalidade</div>
      <p>
        Os cookies são pequenos ficheiros de texto que um website instala no seu
        computador/dispositivo através do qual navega quando o acede (os{" "}
        <strong>“Cookies”</strong>). Os Cookies permitem que, durante
        determinado período, o website se recorde das suas acções e preferências
        de navegação, pelo que são utilizados por nós para processar e gerir da
        forma mais eficiente os seus pedidos, bem como para desenvolver e
        melhorar os nossos serviços e o funcionamento deste website.
        <br />
        <br />
        Notamos que, em geral, os Cookies não incluem detalhes que possam ser
        utilizados para o identificar, apenas ao computador/dispositivo que
        esteja a utilizar.
      </p>
      <div className={styles.title}>Os Cookies Utilizados</div>
      <p>
        Este website procede à instalação de cookies com o único objetivo de
        viabilizar ou optimizar as funcionalidades oferecidas e de melhorar a
        experiência de navegação do utilizador. Para além destes cookies de
        funcionalidade e de sessão, são instalados cookies analíticos por
        ferramentas geridas por terceiros para acompanhar a qualidade da
        experiência do utilizador quando consulta este website.
        <br />
        <br />
        Os Cookies que utilizamos habitualmente, bem como as suas principais
        características (tais como a sua função) são:
        <br />
        <br />
        <strong>
          Cookies estritamente necessários e de Funcionalidade
        </strong>{" "}
        São cookies instalados com o objetivo de viabilizar ou optimizar as
        funcionalidades oferecidas pelo website e de melhorar a experiência de
        navegação do utilizador.
        <br />
        <br />
        <strong>Cookies Analíticos</strong> São cookies instalados por
        ferramentas geridas por terceiros (Google Analytics, Google TagManager,
        etc.) para acompanhar a qualidade da experiência do utilizador na
        navegação neste website.
      </p>
      <div className={styles.title}>Controlo de cookies</div>
      <p>
        A autorização do uso de Cookies concedida pelo utilizador pode ser
        desativada a qualquer momento, parcial ou totalmente, não obstante isso
        colocar em causa algumas funcionalidades dos websites visitados (em
        particular, do presente website).
        <br />
        <br />A forma como faz isso depende do navegador (web browser) que
        utiliza (o “Navegador”), pelo que solicitamos que consulte a função
        "Ajuda" do seu Navegador. Por exemplo:
        <br />
        <br />
      </p>
      <ul>
        <li>
          <span style={{ marginRight: "6px" }}>&#8226;</span>
          Se utiliza Microsoft Internet Explorer, na opção de menu ferramentas >
          opções de internet > privacidade > configuração.
        </li>
        <br />
        <li>
          <span style={{ marginRight: "6px" }}>&#8226;</span>
          Se utiliza Firefox, na opção de menu ferramentas > opções >
          privacidade > cookies.
        </li>
        <br />
        <li>
          <span style={{ marginRight: "6px" }}>&#8226;</span>
          Se utiliza Chrome, na secção de opções > opções avançadas >
          privacidade.
        </li>
        <br />
      </ul>
      <p>
        Assim, o Navegador permite exercer algum controle de Cookies através das
        respectivas configurações, sendo que a maioria dos Navegadores permitem
        bloquear Cookies ou bloquear Cookies de websites específicos ou navegar
        em modo privado.
        <br />
        <br />
        Referimos igualmente que, se autorizar a utilização dos Cookies, eles
        poderão permanecer no seu computador/dispositivo por um longo período
        temporal, pelo que notamos que poderá, a todo o tempo, eliminá-los. A
        título de exemplo, os Navegadores poderão ajudá-lo a apagar os Cookies
        quando fechar este website. No entanto, caso os elimine, deverá ter em
        consideração que isso significa que quaisquer opt-outs ou preferências
        definidas no website poderão ser perdidas.
        <br />
        <br />
        <strong>Google Analytics navegador opt-out</strong> Para fornecer aos
        visitantes mais escolha sobre como os seus dados são recolhidos pelo
        Google Analytics, o Google desenvolveu o Google Analytics Navegador
        Opt-out Add-on. O Google Analytics Navegador Opt-out extra não impede
        que as informações sejam enviadas para o website em si ou para outros
        serviços da web analytics.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://tools.google.com/dlpage/gaoptout?hl=None"
        >
          Saiba mais sobre o Google Analytics Brower Opt-out extra.
        </a>
      </p>
      <div className={styles.btndiv}>
        <button
          type="button"
          onClick={acceptHandler}
          style={{
            backgroundColor: isBottom ? "red" : "transparent",
            color: isBottom ? "white" : "red",
          }}
        >
          Aceitar
        </button>
      </div>
    </div>
  );
}

export default ToS;
